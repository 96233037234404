// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function useLocalStorage(key, defaultValue, decoder, encoder) {
  var match = React.useState(function () {
        try {
          var maybeContent = localStorage.getItem(key);
          if (maybeContent == null) {
            return defaultValue;
          }
          var json = Json.parse(maybeContent);
          if (json !== undefined) {
            return Curry._1(decoder, Caml_option.valFromOption(json));
          } else {
            return defaultValue;
          }
        }
        catch (raw_err){
          var err = Caml_js_exceptions.internalToOCamlException(raw_err);
          console.error("Failed to read key \"" + key + "\" from localStorage: ", err);
          return defaultValue;
        }
      });
  var setStoredValue = match[1];
  var setValue = function (set) {
    try {
      return Curry._1(setStoredValue, (function (oldValue) {
                    var newValue = Curry._1(set, oldValue);
                    var jsonStr = Json.stringify(Curry._1(encoder, newValue));
                    localStorage.setItem(key, jsonStr);
                    return newValue;
                  }));
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      console.error("Failed to set key \"" + key + "\" in localStorage: ", err);
      return ;
    }
  };
  return [
          match[0],
          setValue
        ];
}

export {
  useLocalStorage ,
  
}
/* react Not a pure module */
