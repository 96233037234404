// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Tweet from "../../utils/Tweet.bs.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as AsyncData from "rescript-asyncdata/src/AsyncData.bs.js";
import * as ExternalLink from "../../components/ExternalLink.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as ReactRouterDom from "react-router-dom";

function explainTaskStatus(status) {
  switch (status) {
    case /* Claimed */0 :
        return "Please retweet the tweet in order to earn the reward.";
    case /* Abandoned */1 :
        return "The task was claimed, but was not performed within reasonable time";
    case /* Performed */2 :
        return "You have performed the task. Once it is verified you will get your reward";
    case /* Bungled */3 :
        return "You had retweeted the tweet, but later undid.";
    case /* Verified */4 :
        return "Your task is verified. Wait for the reward to come soon!";
    case /* PaidOut */5 :
        return "You have performed the task and got your reward.";
    
  }
}

function MyTaskPage$TweetCard(Props) {
  var tweet = Props.tweet;
  return React.createElement(Material.Card, {
              children: null
            }, React.createElement(Material.CardContent, {
                  children: React.createElement(Material.Typography, {
                        children: Tweet.shortenTweetText(tweet.text),
                        variant: "body2",
                        component: "p"
                      })
                }), React.createElement(Material.CardActions, {
                  children: React.createElement(ExternalLink.make, {
                        children: "Open Tweet",
                        href: Tweet.tweetUrl(tweet.id)
                      })
                }));
}

var TweetCard = {
  make: MyTaskPage$TweetCard
};

function MyTaskPage$MyTask(Props) {
  var task = Props.task;
  var onTaskChanged = Props.onTaskChanged;
  var task$1 = task.task;
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setCheckState = match[1];
  var checkState = match[0];
  React.useEffect((function () {
          if (typeof checkState !== "number") {
            var match = checkState._0;
            if (match.TAG === /* Ok */0) {
              var t = match._0;
              if (t.TAG === /* Ok */0) {
                Curry._1(onTaskChanged, t._0);
              }
              
            }
            
          }
          
        }), [checkState]);
  var confirmAction;
  if (task$1.status === /* Claimed */0) {
    var onClick = function (_event) {
      Curry._1(setCheckState, (function (param) {
              return /* Loading */1;
            }));
      $$Promise.$$catch(Api.checkTaskPerformance(task$1.id).then(function (task) {
                return Promise.resolve(Curry._1(setCheckState, (function (param) {
                                  return /* Done */{
                                          _0: {
                                            TAG: /* Ok */0,
                                            _0: task
                                          }
                                        };
                                })));
              }), (function (err) {
              return Promise.resolve(Curry._1(setCheckState, (function (param) {
                                return /* Done */{
                                        _0: {
                                          TAG: /* Error */1,
                                          _0: err
                                        }
                                      };
                              })));
            }));
      
    };
    if (typeof checkState === "number") {
      confirmAction = checkState !== 0 ? React.createElement(Material.CircularProgress, {
              disableShrink: true
            }) : React.createElement(Material.Button, {
              children: "I did retweet",
              onClick: onClick,
              size: "small",
              variant: "contained"
            });
    } else {
      var match$1 = checkState._0;
      var exit = 0;
      if (match$1.TAG === /* Ok */0) {
        var match$2 = match$1._0;
        if (match$2.TAG === /* Ok */0) {
          confirmAction = React.createElement(Material.Alert, {
                children: "You did it! It will take 24h-48h until you get your reward.",
                severity: "success"
              });
        } else if (match$2._0) {
          confirmAction = React.createElement(Material.Alert, {
                children: "It does not look like you have performed the task.",
                severity: "warning"
              });
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
      if (exit === 1) {
        confirmAction = React.createElement(Material.Alert, {
              children: "Oops. Something went wrong. Try to reload?",
              severity: "error"
            });
      }
      
    }
  } else {
    confirmAction = React.createElement(React.Fragment, undefined);
  }
  return React.createElement(React.Fragment, undefined, "Reward: " + Format.formatNearAmount4(task$1.contractorReward), React.createElement("br", undefined), "Status: " + Types.TaskStatusView.display(task$1.status), React.createElement(Material.Typography, {
                  children: explainTaskStatus(task$1.status),
                  color: "text.secondary"
                }), React.createElement("br", undefined), confirmAction, React.createElement("br", undefined), React.createElement(MyTaskPage$TweetCard, {
                  tweet: task.tweet
                }));
}

var MyTask = {
  make: MyTaskPage$MyTask
};

function MyTaskPage$MainContent(Props) {
  var taskId = Props.taskId;
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setMyTask = match[1];
  var myTask = match[0];
  React.useEffect((function () {
          Api.getMyTask(taskId).then(function (task) {
                return Promise.resolve(Curry._1(setMyTask, (function (param) {
                                  return /* Done */{
                                          _0: task
                                        };
                                })));
              });
          
        }), [taskId]);
  var onTaskChanged = function (newTask) {
    return Curry._1(setMyTask, (function (prevData) {
                  return AsyncData.map(prevData, (function (prevTaskAndTweet) {
                                return {
                                        task: newTask,
                                        tweet: prevTaskAndTweet.tweet
                                      };
                              }));
                }));
  };
  if (typeof myTask === "number") {
    return React.createElement(Material.CircularProgress, {
                disableShrink: true
              });
  } else {
    return React.createElement(MyTaskPage$MyTask, {
                task: myTask._0,
                onTaskChanged: onTaskChanged
              });
  }
}

var MainContent = {
  make: MyTaskPage$MainContent
};

function MyTaskPage(Props) {
  var match = ReactRouterDom.useParams();
  var taskId = /* TaskId */{
    _0: match.taskId
  };
  return React.createElement(ApplicationBar.make, {
              title: "Task",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "New tasks",
                            to: "/tasks/claimable"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Past tasks",
                            to: "/tasks/my"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "Task",
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true
                    }), React.createElement(MyTaskPage$MainContent, {
                      taskId: taskId
                    })));
}

var make = MyTaskPage;

export {
  explainTaskStatus ,
  TweetCard ,
  MyTask ,
  MainContent ,
  make ,
  
}
/* Api Not a pure module */
