// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ListMyOrdersPage from "./ListMyOrdersPage.bs.js";

function ListMyPastOrdersPage(Props) {
  var isInactive = function (order) {
    return order.retweetOrder.status !== /* Active */0;
  };
  return React.createElement(ListMyOrdersPage.make, {
              title: "My Past Orders",
              filter: isInactive
            });
}

var make = ListMyPastOrdersPage;

export {
  make ,
  
}
/* react Not a pure module */
