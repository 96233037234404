// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Material from "@mui/material";
import * as ApplicationBar from "../components/ApplicationBar.bs.js";
import * as ReactRouterDom from "react-router-dom";

function DashboardPage(Props) {
  var userName = Props.userName;
  return React.createElement(ApplicationBar.make, {
              title: "Welcome",
              children: React.createElement("main", undefined, React.createElement("div", undefined, React.createElement(Material.Toolbar, {}), React.createElement(Material.Container, {
                            children: null,
                            maxWidth: "md"
                          }, React.createElement(Material.Typography, {
                                children: "Hello " + userName,
                                variant: "h3",
                                component: "h3",
                                align: "center",
                                gutterBottom: true,
                                color: "text.primary"
                              }), React.createElement(Material.Typography, {
                                children: React.createElement(ReactRouterDom.Link, {
                                      children: "Start by depositing some NEAR to your balance in order to promote your tweets.",
                                      to: "/deposit"
                                    }),
                                variant: "h4",
                                component: "h4",
                                align: "center",
                                gutterBottom: true,
                                color: "text.primary"
                              }), React.createElement(Material.Typography, {
                                children: "OR",
                                variant: "h4",
                                component: "h4",
                                align: "center",
                                gutterBottom: true,
                                color: "text.primary"
                              }), React.createElement(Material.Typography, {
                                children: React.createElement(ReactRouterDom.Link, {
                                      children: "Check available tasks if you want to earn NEAR",
                                      to: "/tasks/claimable"
                                    }),
                                variant: "h4",
                                component: "h4",
                                align: "center",
                                gutterBottom: true,
                                color: "text.primary"
                              }))))
            });
}

var make = DashboardPage;

export {
  make ,
  
}
/* react Not a pure module */
