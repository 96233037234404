// Generated by ReScript, PLEASE EDIT WITH CARE


function formatNearWithPrecision(amount, precision) {
  var fixedAmount = amount.toFixed(precision);
  return fixedAmount + " Ⓝ ";
}

function formatNearAmount(amount) {
  return formatNearWithPrecision(amount, 2);
}

function formatNearAmount4(amount) {
  return formatNearWithPrecision(amount, 4);
}

function formatDateTimeLocal(utcDateTime) {
  return new Date(utcDateTime).toLocaleString();
}

export {
  formatNearWithPrecision ,
  formatNearAmount ,
  formatNearAmount4 ,
  formatDateTimeLocal ,
  
}
/* No side effect */
