// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Material from "@mui/material";

function SkeletonCardList(Props) {
  var boxStyle = {
    flexGrow: "1"
  };
  var buildCardItem = function (index) {
    return React.createElement(Material.Grid, {
                children: null,
                item: true,
                xs: 12,
                md: 6,
                lg: 4,
                key: index.toString()
              }, React.createElement(Material.Skeleton, {
                    variant: "rectangular",
                    height: 100
                  }), React.createElement(Material.Skeleton, {}));
  };
  var items = Belt_Array.map([
        1,
        2,
        3,
        4,
        5
      ], buildCardItem);
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: items,
                    container: true,
                    spacing: 2
                  }),
              sx: boxStyle
            });
}

var make = SkeletonCardList;

export {
  make ,
  
}
/* react Not a pure module */
