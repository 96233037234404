// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Tweet from "../../utils/Tweet.bs.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ExternalLink from "../../components/ExternalLink.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as SkeletonCardList from "../../components/SkeletonCardList.bs.js";
import * as ReactRouterDom from "react-router-dom";

function cmpByReward(a, b) {
  if (a.reward.gt(b.reward)) {
    return 1;
  } else if (b.reward.gt(a.reward)) {
    return -1;
  } else {
    return 0;
  }
}

function cmpByIsNew(a, b) {
  var match = a.isNew;
  var match$1 = b.isNew;
  if (match) {
    if (match$1) {
      return 0;
    } else {
      return 1;
    }
  } else if (match$1) {
    return -1;
  } else {
    return 0;
  }
}

function sortByReward(orders) {
  return Belt_Array.reverse(Belt_SortArray.stableSortBy(Belt_SortArray.stableSortBy(orders, cmpByReward), cmpByIsNew));
}

function ListClaimableTasksPage$ClaimTaskButton(Props) {
  var orderId = Props.orderId;
  var history = ReactRouterDom.useHistory();
  var navigateToTask = function (id) {
    history.push("/tasks/" + id._0);
    
  };
  var onClick = function (param) {
    Api.claimOrderTask(orderId).then(function (claimResult) {
          var tmp;
          if (claimResult.TAG === /* Ok */0) {
            tmp = navigateToTask(claimResult._0.id);
          } else {
            var taskId = claimResult._0;
            if (typeof taskId === "number") {
              console.log(taskId);
              tmp = undefined;
            } else {
              tmp = navigateToTask(taskId._0);
            }
          }
          return Promise.resolve(tmp);
        });
    
  };
  return React.createElement(Material.Button, {
              children: "CLAIM",
              onClick: onClick,
              size: "small",
              variant: "contained",
              color: "primary"
            });
}

var ClaimTaskButton = {
  make: ListClaimableTasksPage$ClaimTaskButton
};

function ListClaimableTasksPage$ClaimableOrder(Props) {
  var order = Props.order;
  var tweet = order.tweet;
  var tweetUrl = Tweet.tweetUrl(tweet.id);
  var actionsStyle = {
    justifyContent: "space-between"
  };
  var newChip = order.isNew ? React.createElement(Material.Chip, {
          label: "NEW 🔥",
          size: "small"
        }) : "";
  return React.createElement(Material.Card, {
              children: null
            }, React.createElement(Material.CardContent, {
                  children: React.createElement(Material.Grid, {
                        children: null,
                        container: true
                      }, React.createElement(Material.Grid, {
                            children: null,
                            item: true,
                            xs: 8
                          }, React.createElement(Material.Typography, {
                                children: "Reward: " + Format.formatNearAmount4(order.reward),
                                color: "text.secondary"
                              }), React.createElement(Material.Typography, {
                                children: Tweet.shortenTweetText(tweet.text),
                                variant: "body2",
                                component: "p"
                              })), React.createElement(Material.Grid, {
                            children: React.createElement(Material.Grid, {
                                  children: newChip,
                                  container: true,
                                  justifyContent: "flex-end"
                                }),
                            item: true,
                            xs: 4
                          }))
                }), React.createElement(Material.CardActions, {
                  children: null,
                  style: actionsStyle
                }, React.createElement(ListClaimableTasksPage$ClaimTaskButton, {
                      orderId: order.id
                    }), React.createElement(ExternalLink.make, {
                      children: "Open Tweet",
                      href: tweetUrl
                    })));
}

var ClaimableOrder = {
  make: ListClaimableTasksPage$ClaimableOrder
};

function ListClaimableTasksPage$ClaimableOrderList(Props) {
  var orders = Props.orders;
  var orderElements = Belt_Array.map(orders, (function (order) {
          var key = Types.RetweetOrderId.toString(order.id);
          return React.createElement(Material.Grid, {
                      children: React.createElement(ListClaimableTasksPage$ClaimableOrder, {
                            order: order
                          }),
                      item: true,
                      xs: 12,
                      md: 6,
                      lg: 4,
                      key: key
                    });
        }));
  var boxStyle = {
    flexGrow: "1"
  };
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: orderElements,
                    container: true,
                    spacing: 2
                  }),
              sx: boxStyle
            });
}

var ClaimableOrderList = {
  make: ListClaimableTasksPage$ClaimableOrderList
};

function ListClaimableTasksPage$MainContent(Props) {
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setClaimableOrders = match[1];
  var claimableOrders = match[0];
  React.useEffect((function () {
          $$Promise.$$catch(Api.getClaimableRetweetOrders(undefined).then(function (orders) {
                    var orders$1 = /* Done */{
                      _0: {
                        TAG: /* Ok */0,
                        _0: sortByReward(orders)
                      }
                    };
                    return Promise.resolve(Curry._1(setClaimableOrders, (function (param) {
                                      return orders$1;
                                    })));
                  }), (function (err) {
                  var orders = /* Done */{
                    _0: {
                      TAG: /* Error */1,
                      _0: err
                    }
                  };
                  return Promise.resolve(Curry._1(setClaimableOrders, (function (param) {
                                    return orders;
                                  })));
                }));
          
        }), []);
  if (typeof claimableOrders === "number") {
    return React.createElement(SkeletonCardList.make, {});
  }
  var orders = claimableOrders._0;
  if (orders.TAG === /* Ok */0) {
    return React.createElement(ListClaimableTasksPage$ClaimableOrderList, {
                orders: orders._0
              });
  } else {
    return "Oops. Something went wrong";
  }
}

var MainContent = {
  make: ListClaimableTasksPage$MainContent
};

function ListClaimableTasksPage(Props) {
  return React.createElement(ApplicationBar.make, {
              title: "Task",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "New tasks",
                            to: "/tasks/claimable"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Past tasks",
                            to: "/tasks/my"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "Available Tasks",
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true
                    }), React.createElement(ListClaimableTasksPage$MainContent, {})));
}

var make = ListClaimableTasksPage;

export {
  cmpByReward ,
  cmpByIsNew ,
  sortByReward ,
  ClaimTaskButton ,
  ClaimableOrder ,
  ClaimableOrderList ,
  MainContent ,
  make ,
  
}
/* Api Not a pure module */
