// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function string(a, b) {
  var cmpResult = a.localeCompare(b);
  if (cmpResult > 0.0) {
    return 1;
  } else if (cmpResult < 0.0) {
    return -1;
  } else {
    return 0;
  }
}

var Cmp = {
  string: string
};

function sortBy(items, cmp, fetchProp) {
  var itemCmp = function (a, b) {
    return Curry._2(cmp, Curry._1(fetchProp, a), Curry._1(fetchProp, b));
  };
  return Belt_SortArray.stableSortBy(items, itemCmp);
}

var Sort = {
  sortBy: sortBy
};

export {
  Cmp ,
  Sort ,
  
}
/* No side effect */
