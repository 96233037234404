// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Big from "../../bindings/Big.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as BigJs from "big.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../contexts/UserContext.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as ReactRouterDom from "react-router-dom";

function parseTweetId(tweetUrl) {
  var regex = /https:\/\/(?:mobile\.)?twitter\.com\/.*\/status\/(\d+)/i;
  return Belt_Option.flatMap(Caml_option.null_to_opt(regex.exec(tweetUrl)), (function (result) {
                return Belt_Option.map(Caml_option.nullable_to_opt(Caml_array.get(result, 1)), Types.TweetId.fromString);
              }));
}

var $$parseInt = Belt_Int.fromString;

function emptyFormParams(param) {
  return {
          tweetUrl: "",
          budget: "",
          numberOfTasks: "",
          taskCost: undefined
        };
}

function emptyFormErrors(param) {
  return {
          tweetUrl: undefined,
          budget: undefined,
          numberOfTasks: undefined,
          taskCost: undefined
        };
}

function validateTweetUrl(tweetUrl) {
  var tweetId = parseTweetId(tweetUrl);
  if (tweetId !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: tweetId
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Tweet URL is invalid"
          };
  }
}

function validateBudget(budget) {
  var budget$1 = Big.parse(budget);
  if (budget$1 === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Invalid"
          };
  }
  var budget$2 = Caml_option.valFromOption(budget$1);
  if (budget$2.gt(Big.fromString("1000"))) {
    return {
            TAG: /* Error */1,
            _0: "Uh. Is it not too much? Take it easy."
          };
  } else if (budget$2.lt(Big.fromString("0.01"))) {
    return {
            TAG: /* Error */1,
            _0: "Budget must be greater than 0.01 NEAR"
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: budget$2
          };
  }
}

function validateNumberOfTasks(value) {
  var number = Belt_Int.fromString(value);
  if (number !== undefined) {
    if (number < 1) {
      return {
              TAG: /* Error */1,
              _0: "Must not be less than 1"
            };
    } else if (number > 10000) {
      return {
              TAG: /* Error */1,
              _0: "Sorry. 10K is maximum at the moment"
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: number
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: "Invalid"
          };
  }
}

function validateTaskCost(taskCost) {
  if (taskCost !== undefined && Caml_option.valFromOption(taskCost).lt(Big.fromString("0.001"))) {
    return {
            TAG: /* Error */1,
            _0: "Can not be less than 0.001 Ⓝ. Please adjust Budget or Number of retweets."
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function getError(result) {
  if (result.TAG === /* Ok */0) {
    return ;
  } else {
    return Caml_option.some(result._0);
  }
}

function validate(params) {
  var tweetUrlRes = validateTweetUrl(params.tweetUrl);
  var budgetRes = validateBudget(params.budget);
  var numberOfTasksRes = validateNumberOfTasks(params.numberOfTasks);
  var taskCostRes = validateTaskCost(params.taskCost);
  if (tweetUrlRes.TAG === /* Ok */0 && budgetRes.TAG === /* Ok */0 && numberOfTasksRes.TAG === /* Ok */0 && taskCostRes.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              tweetId: tweetUrlRes._0,
              budget: budgetRes._0,
              numberOfTasks: numberOfTasksRes._0
            }
          };
  }
  return {
          TAG: /* Error */1,
          _0: {
            tweetUrl: getError(tweetUrlRes),
            budget: getError(budgetRes),
            numberOfTasks: getError(numberOfTasksRes),
            taskCost: getError(taskCostRes)
          }
        };
}

function convertCreateOrderErrorToFormErrors(err) {
  if (typeof err === "number") {
    switch (err) {
      case /* ActiveOrderAlreadyExists */0 :
          return {
                  tweetUrl: "An active order with this tweet already exist",
                  budget: undefined,
                  numberOfTasks: undefined,
                  taskCost: undefined
                };
      case /* FailedToObtainTweet */1 :
          return {
                  tweetUrl: "It looks like the tweet does not exist or not available for public",
                  budget: undefined,
                  numberOfTasks: undefined,
                  taskCost: undefined
                };
      case /* InvalidBudget */2 :
          return {
                  tweetUrl: undefined,
                  budget: "Invalid",
                  numberOfTasks: undefined,
                  taskCost: undefined
                };
      case /* InvalidNumberOfTasks */3 :
          return {
                  tweetUrl: undefined,
                  budget: undefined,
                  numberOfTasks: "Invalid",
                  taskCost: undefined
                };
      
    }
  } else {
    var msg = "You have only " + Format.formatNearAmount4(err.availableBalance) + " available";
    return {
            tweetUrl: undefined,
            budget: msg,
            numberOfTasks: undefined,
            taskCost: undefined
          };
  }
}

function NewOrderPage$OrderForm(Props) {
  var match = React.useContext(UserContext.context);
  var reloadUser = match.reloadUser;
  var match$1 = React.useState(function () {
        return {
                tweetUrl: "",
                budget: "",
                numberOfTasks: "",
                taskCost: undefined
              };
      });
  var setFormParams = match$1[1];
  var formParams = match$1[0];
  var match$2 = React.useState(function () {
        return {
                tweetUrl: undefined,
                budget: undefined,
                numberOfTasks: undefined,
                taskCost: undefined
              };
      });
  var setFormErrors = match$2[1];
  var formErrors = match$2[0];
  React.useEffect((function () {
          var match = Big.parse(formParams.budget);
          var match$1 = Belt_Int.fromString(formParams.numberOfTasks);
          var taskCost;
          if (match !== undefined && match$1 !== undefined) {
            var number = BigJs.Big(match$1);
            taskCost = Caml_option.some(Caml_option.valFromOption(match).div(number));
          } else {
            taskCost = undefined;
          }
          Curry._1(setFormParams, (function (oldParams) {
                  return {
                          tweetUrl: oldParams.tweetUrl,
                          budget: oldParams.budget,
                          numberOfTasks: oldParams.numberOfTasks,
                          taskCost: taskCost
                        };
                }));
          
        }), [
        formParams.budget,
        formParams.numberOfTasks
      ]);
  var history = ReactRouterDom.useHistory();
  var handleTweetUrlChange = function ($$event) {
    var tweetUrl = $$event.target.value;
    return Curry._1(setFormParams, (function (oldParams) {
                  return {
                          tweetUrl: tweetUrl,
                          budget: oldParams.budget,
                          numberOfTasks: oldParams.numberOfTasks,
                          taskCost: oldParams.taskCost
                        };
                }));
  };
  var handleNumberOfTasksChange = function ($$event) {
    var numberOfTasks = $$event.target.value;
    return Curry._1(setFormParams, (function (oldParams) {
                  return {
                          tweetUrl: oldParams.tweetUrl,
                          budget: oldParams.budget,
                          numberOfTasks: numberOfTasks,
                          taskCost: oldParams.taskCost
                        };
                }));
  };
  var handleBudgetChange = function ($$event) {
    var budget = $$event.target.value;
    return Curry._1(setFormParams, (function (oldParams) {
                  return {
                          tweetUrl: oldParams.tweetUrl,
                          budget: budget,
                          numberOfTasks: oldParams.numberOfTasks,
                          taskCost: oldParams.taskCost
                        };
                }));
  };
  var clickHandler = function (param) {
    var validParams = validate(formParams);
    if (validParams.TAG === /* Ok */0) {
      Api.createRetweetOrder(validParams._0).then(function (result) {
            if (result.TAG === /* Ok */0) {
              Curry._1(reloadUser, undefined);
              history.push("/orders/my/active");
            } else {
              var errors = convertCreateOrderErrorToFormErrors(result._0);
              Curry._1(setFormErrors, (function (param) {
                      return errors;
                    }));
            }
            return Promise.resolve(undefined);
          });
      return ;
    }
    var errors = validParams._0;
    return Curry._1(setFormErrors, (function (param) {
                  return errors;
                }));
  };
  var helperTextOrError = function (helperText, error) {
    if (error !== undefined) {
      return React.createElement(Material.FormHelperText, {
                  children: React.createElement("b", undefined, error)
                });
    } else {
      return React.createElement(Material.FormHelperText, {
                  children: helperText
                });
    }
  };
  var costToString = function (taskCost) {
    if (taskCost !== undefined) {
      return Format.formatNearAmount4(Caml_option.valFromOption(taskCost));
    } else {
      return "";
    }
  };
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: null,
                    container: true,
                    spacing: 2
                  }, React.createElement(Material.Grid, {
                        children: React.createElement(Material.FormControl, {
                              children: null,
                              fullWidth: true,
                              error: Belt_Option.isSome(formErrors.tweetUrl)
                            }, React.createElement(Material.TextField, {
                                  label: "Tweet URL",
                                  value: formParams.tweetUrl,
                                  onChange: handleTweetUrlChange,
                                  fullWidth: true,
                                  required: true,
                                  id: "tweet-url"
                                }), helperTextOrError("URL of Tweet you want to promote", formErrors.tweetUrl)),
                        item: true,
                        xs: 12
                      }), React.createElement(Material.Grid, {
                        children: React.createElement(Material.FormControl, {
                              children: null,
                              fullWidth: true,
                              error: Belt_Option.isSome(formErrors.budget)
                            }, React.createElement(Material.TextField, {
                                  label: "Budget Ⓝ",
                                  value: formParams.budget,
                                  onChange: handleBudgetChange,
                                  fullWidth: true,
                                  required: true,
                                  id: "budget"
                                }), helperTextOrError("Maximum budget you want to spend", formErrors.budget)),
                        item: true,
                        xs: 12,
                        md: 4
                      }), React.createElement(Material.Grid, {
                        children: React.createElement(Material.FormControl, {
                              children: null,
                              fullWidth: true,
                              error: Belt_Option.isSome(formErrors.numberOfTasks)
                            }, React.createElement(Material.TextField, {
                                  label: "Number of retweets",
                                  value: formParams.numberOfTasks,
                                  onChange: handleNumberOfTasksChange,
                                  fullWidth: true,
                                  required: true,
                                  id: "number-of-tasks"
                                }), helperTextOrError("Number of retweets you want to get for the given budget", formErrors.numberOfTasks)),
                        item: true,
                        xs: 12,
                        md: 4
                      }), React.createElement(Material.Grid, {
                        children: React.createElement(Material.FormControl, {
                              children: null,
                              fullWidth: true,
                              error: Belt_Option.isSome(formErrors.numberOfTasks)
                            }, React.createElement(Material.TextField, {
                                  label: "Retweet cost (calculated autmatically)",
                                  value: costToString(formParams.taskCost),
                                  fullWidth: true,
                                  id: "retweet-cost",
                                  disabled: true
                                }), helperTextOrError("Cost of a single retweet. Calculated automatically based on Budget and Number of Retweets", formErrors.taskCost)),
                        item: true,
                        xs: 12,
                        md: 4
                      }), React.createElement(Material.Grid, {
                        children: React.createElement(Material.Grid, {
                              children: React.createElement(Material.Button, {
                                    children: "Create Retweet Order",
                                    onClick: clickHandler,
                                    variant: "contained",
                                    color: "primary"
                                  }),
                              container: true,
                              justifyContent: "center"
                            }),
                        item: true,
                        xs: 12
                      })),
              flexGrow: 1
            });
}

var OrderForm = {
  make: NewOrderPage$OrderForm
};

function NewOrderPage(Props) {
  return React.createElement(ApplicationBar.make, {
              title: "Create New Order",
              children: React.createElement(Material.Container, {
                    children: null
                  }, React.createElement(Material.Toolbar, {}), React.createElement(Material.Typography, {
                        children: "Create retweet order",
                        variant: "h4",
                        component: "h1",
                        align: "center",
                        gutterBottom: true
                      }), React.createElement(NewOrderPage$OrderForm, {}))
            });
}

var make = NewOrderPage;

export {
  parseTweetId ,
  $$parseInt ,
  emptyFormParams ,
  emptyFormErrors ,
  validateTweetUrl ,
  validateBudget ,
  validateNumberOfTasks ,
  validateTaskCost ,
  getError ,
  validate ,
  convertCreateOrderErrorToFormErrors ,
  OrderForm ,
  make ,
  
}
/* Api Not a pure module */
