// Generated by ReScript, PLEASE EDIT WITH CARE


var BrowserLocalStorageKeyStore = {};

function make(networkId, nodeUrl, headers, walletUrl, keyStore, helperUrl, initialBalance, masterAccount, param) {
  return {
          networkId: networkId,
          nodeUrl: nodeUrl,
          headers: headers,
          walletUrl: walletUrl,
          keyStore: keyStore,
          helperUrl: helperUrl,
          initialBalance: initialBalance,
          masterAccount: masterAccount
        };
}

var NearConfig = {
  make: make
};

var Near = {};

var Account = {};

var ConnectedWalletAccount = {};

var WalletConnection = {};

var ContractMethods = {};

var Contract = {};

export {
  BrowserLocalStorageKeyStore ,
  NearConfig ,
  Near ,
  Account ,
  ConnectedWalletAccount ,
  WalletConnection ,
  ContractMethods ,
  Contract ,
  
}
/* No side effect */
