// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Near from "../utils/Near.bs.js";
import * as Types from "../shared/Types.bs.js";
import * as React from "react";
import * as ExternalLink from "./ExternalLink.bs.js";

function LinkToNearAccount(Props) {
  var networkId = Props.networkId;
  var account = Props.account;
  var url = Near.nearAccountUrl(networkId, account);
  var account$1 = Types.NearAccountId.toString(account);
  return React.createElement(ExternalLink.make, {
              children: account$1,
              href: url
            });
}

var make = LinkToNearAccount;

export {
  make ,
  
}
/* Near Not a pure module */
