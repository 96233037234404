// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Material from "@mui/material";
import * as ConfirmationDialog from "./ConfirmationDialog.bs.js";

function ButtonWithConfirmation(Props) {
  var children = Props.children;
  var onConfirm = Props.onConfirm;
  var confirmTextOpt = Props.confirmText;
  var cancelTextOpt = Props.cancelText;
  var titleOpt = Props.title;
  var disabledOpt = Props.disabled;
  var contentOpt = Props.content;
  var confirmText = confirmTextOpt !== undefined ? confirmTextOpt : "Yes";
  var cancelText = cancelTextOpt !== undefined ? cancelTextOpt : "Back";
  var title = titleOpt !== undefined ? titleOpt : "Confirmation";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var content = contentOpt !== undefined ? Caml_option.valFromOption(contentOpt) : "Are you sure you want to perform this action?";
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var onClick = function (param) {
    return Curry._1(setIsOpen, (function (param) {
                  return true;
                }));
  };
  var onDialogCancel = function (param) {
    return Curry._1(setIsOpen, (function (param) {
                  return false;
                }));
  };
  var onDialogConfirm = function (param) {
    Curry._1(onConfirm, undefined);
    return Curry._1(setIsOpen, (function (param) {
                  return false;
                }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Material.Button, {
                  children: children,
                  onClick: onClick,
                  disabled: disabled
                }), React.createElement(ConfirmationDialog.make, {
                  onConfirm: onDialogConfirm,
                  onCancel: onDialogCancel,
                  isOpen: match[0],
                  title: title,
                  confirmText: confirmText,
                  cancelText: cancelText,
                  content: content
                }));
}

var make = ButtonWithConfirmation;

export {
  make ,
  
}
/* react Not a pure module */
