// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as ReactRouterDom from "react-router-dom";
import * as LinkToNearTransaction from "../../components/LinkToNearTransaction.bs.js";

function renderTableCell(value) {
  return React.createElement(Material.TableCell, {
              children: value
            });
}

var greenStyle = {
  color: "green"
};

var redStyle = {
  color: "red"
};

function renderAmountCell(tr) {
  var val = Format.formatNearAmount4(tr.amount);
  var match = Types.LedgerTransactionView.isDebit(tr) ? [
      "+",
      greenStyle
    ] : [
      "-",
      redStyle
    ];
  var prefixedVal = match[0] + val;
  return React.createElement(Material.TableCell, {
              children: React.createElement(Material.Typography, {
                    children: prefixedVal,
                    sx: match[1]
                  })
            });
}

function renderNearTransactionHash(networkId, optHash) {
  if (optHash !== undefined) {
    return React.createElement(Material.TableCell, {
                children: React.createElement(LinkToNearTransaction.make, {
                      networkId: networkId,
                      hash: Caml_option.valFromOption(optHash)
                    })
              });
  } else {
    return renderTableCell("");
  }
}

function renderTransactionListTemplate(tableBodyContent) {
  var tableStyle = {
    minWidth: "650"
  };
  return React.createElement(Material.TableContainer, {
              children: React.createElement(Material.Table, {
                    children: null,
                    size: "small",
                    sx: tableStyle
                  }, React.createElement(Material.TableHead, {
                        children: React.createElement(Material.TableRow, {
                              children: null,
                              key: "transactionListHeader"
                            }, renderTableCell("Date"), renderTableCell("Type"), renderTableCell("Amount"), renderTableCell("NEAR Transaction"))
                      }), React.createElement(Material.TableBody, {
                        children: tableBodyContent
                      }))
            });
}

function renderTransactionList(transactions, networkId) {
  var renderTransactionRow = function (tr) {
    return React.createElement(Material.TableRow, {
                children: null,
                key: tr.createdAt
              }, renderTableCell(Format.formatDateTimeLocal(tr.createdAt)), renderTableCell(Types.LedgerTransactionViewKind.toString(tr.kind)), renderAmountCell(tr), renderNearTransactionHash(networkId, Types.LedgerTransactionViewKind.getNearTransactionHash(tr.kind)));
  };
  return renderTransactionListTemplate(Belt_Array.map(transactions, renderTransactionRow));
}

function renderTransactionListSkeleton(param) {
  var skeletonRow = function (index) {
    return React.createElement(Material.TableRow, {
                children: null,
                key: String(index)
              }, React.createElement(Material.TableCell, {
                    children: React.createElement(Material.Skeleton, {
                          variant: "text"
                        })
                  }), React.createElement(Material.TableCell, {
                    children: React.createElement(Material.Skeleton, {
                          variant: "text"
                        })
                  }), React.createElement(Material.TableCell, {
                    children: React.createElement(Material.Skeleton, {
                          variant: "text"
                        })
                  }), React.createElement(Material.TableCell, {
                    children: React.createElement(Material.Skeleton, {
                          variant: "text"
                        })
                  }));
  };
  return renderTransactionListTemplate(Belt_Array.map([
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8
                ], skeletonRow));
}

function ListMyTransactions$TransactinoList(Props) {
  var nearNetworkId = Props.nearNetworkId;
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setMyTransactions = match[1];
  var myTransactions = match[0];
  React.useEffect((function () {
          Api.getMyTransactions(undefined).then(function (transactions) {
                return Promise.resolve(Curry._1(setMyTransactions, (function (param) {
                                  return /* Done */{
                                          _0: transactions
                                        };
                                })));
              });
          
        }), []);
  if (typeof myTransactions === "number") {
    return renderTransactionListSkeleton(undefined);
  } else {
    return renderTransactionList(myTransactions._0, nearNetworkId);
  }
}

var TransactinoList = {
  renderTableCell: renderTableCell,
  greenStyle: greenStyle,
  redStyle: redStyle,
  renderAmountCell: renderAmountCell,
  renderNearTransactionHash: renderNearTransactionHash,
  renderTransactionListTemplate: renderTransactionListTemplate,
  renderTransactionList: renderTransactionList,
  renderTransactionListSkeleton: renderTransactionListSkeleton,
  make: ListMyTransactions$TransactinoList
};

function ListMyTransactions(Props) {
  var nearNetworkId = Props.nearNetworkId;
  return React.createElement(ApplicationBar.make, {
              title: "My Transactions",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "Transactions",
                            to: "/transactions/my"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Deposit",
                            to: "/deposit"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Withdraw",
                            to: "/withdraw"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "Recent Transactions",
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true,
                      color: "text.primary"
                    }), React.createElement(ListMyTransactions$TransactinoList, {
                      nearNetworkId: nearNetworkId
                    })));
}

var make = ListMyTransactions;

export {
  TransactinoList ,
  make ,
  
}
/* Api Not a pure module */
