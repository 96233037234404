// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ListMyOrdersPage from "./ListMyOrdersPage.bs.js";

function ListMyActiveOrdersPage(Props) {
  var isActive = function (order) {
    return order.retweetOrder.status === /* Active */0;
  };
  return React.createElement(ListMyOrdersPage.make, {
              title: "My Active Orders",
              filter: isActive
            });
}

var make = ListMyActiveOrdersPage;

export {
  make ,
  
}
/* react Not a pure module */
