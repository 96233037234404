// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Format from "../utils/Format.bs.js";
import * as AsyncData from "rescript-asyncdata/src/AsyncData.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InhypedIcon from "../icons/InhypedIcon.bs.js";
import * as UserContext from "../contexts/UserContext.bs.js";
import * as Material from "@mui/material";
import * as ReactRouterDom from "react-router-dom";
import * as ApplicationBarContext from "../contexts/ApplicationBarContext.bs.js";
import Menu from "@mui/icons-material/Menu";
import Work from "@mui/icons-material/Work";
import Assignment from "@mui/icons-material/Assignment";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import * as StyledComponents from "./ApplicationBar/styledComponents";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";

function ApplicationBar$UnstyledLink(Props) {
  var to = Props.to;
  var children = Props.children;
  var style = {
    textDecoration: "none"
  };
  return React.createElement(ReactRouterDom.Link, {
              children: children,
              style: style,
              to: to
            });
}

var UnstyledLink = {
  make: ApplicationBar$UnstyledLink
};

function ApplicationBar$MenuItems(Props) {
  var menuItems = [
    {
      path: "/orders/my/active",
      name: "Orders",
      icon: React.createElement(Work, {})
    },
    {
      path: "/tasks/claimable",
      name: "Tasks",
      icon: React.createElement(Assignment, {})
    },
    {
      path: "/transactions/my",
      name: "Transactions",
      icon: React.createElement(AccountBalanceWallet, {})
    }
  ];
  var menuLinks = Belt_Array.map(menuItems, (function (menuItem) {
          var name = menuItem.name;
          return React.createElement(ApplicationBar$UnstyledLink, {
                      to: menuItem.path,
                      children: React.createElement(Material.ListItem, {
                            children: null,
                            button: true,
                            key: name
                          }, React.createElement(Material.ListItemIcon, {
                                children: menuItem.icon
                              }), React.createElement(Material.ListItemText, {
                                primary: name
                              })),
                      key: name
                    });
        }));
  return React.createElement(Material.List, {
              children: menuLinks
            });
}

var MenuItems = {
  make: ApplicationBar$MenuItems
};

function ApplicationBar$UserBalance(Props) {
  var amount = Props.amount;
  if (amount === undefined) {
    return React.createElement("span", undefined, "Balance: ... ");
  }
  var balance = Caml_option.valFromOption(amount);
  return React.createElement(Material.Tooltip, {
              children: React.createElement("span", undefined, "Balance: " + Format.formatNearAmount(balance)),
              arrow: true,
              title: Format.formatNearAmount4(balance)
            });
}

var UserBalance = {
  make: ApplicationBar$UserBalance
};

function ApplicationBar$DepositIconButton(Props) {
  return React.createElement(ReactRouterDom.Link, {
              children: React.createElement(Material.Tooltip, {
                    children: React.createElement(AddCircleOutline, {
                          fontSize: "large"
                        }),
                    arrow: true,
                    title: "Deposit"
                  }),
              style: {
                color: "white",
                textDecoration: "none"
              },
              to: "/deposit"
            });
}

var DepositIconButton = {
  make: ApplicationBar$DepositIconButton
};

function asyncOptionMap(asyncOption, f) {
  return AsyncData.map(asyncOption, (function (maybeValue) {
                return Belt_Option.map(maybeValue, f);
              }));
}

function ApplicationBar$RightSide(Props) {
  var match = React.useContext(UserContext.context);
  var asyncUser = match.user;
  var maybeUser;
  if (typeof asyncUser === "number") {
    maybeUser = undefined;
  } else {
    var user = asyncUser._0;
    maybeUser = user !== undefined ? user : undefined;
  }
  var amount = Belt_Option.map(maybeUser, (function (u) {
          return u.balance;
        }));
  var avatarUrl = Belt_Option.map(maybeUser, (function (u) {
          return u.profileImageUrl;
        }));
  var match$1 = React.useState(function () {
        
      });
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  var isOpen = Belt_Option.isSome(anchorEl);
  var handleMenu = function (e) {
    var ct = e.currentTarget;
    return Curry._1(setAnchorEl, (function (param) {
                  return Caml_option.some(ct);
                }));
  };
  var handleClose = function (param) {
    return Curry._1(setAnchorEl, (function (param) {
                  
                }));
  };
  var origin = {
    horizontal: "right",
    vertical: "top"
  };
  var tmp = {};
  if (avatarUrl !== undefined) {
    tmp.src = avatarUrl;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ApplicationBar$UserBalance, {
                  amount: amount
                }), React.createElement("span", {
                  style: {
                    width: "8px"
                  }
                }), React.createElement(ApplicationBar$DepositIconButton, {}), React.createElement("span", {
                  style: {
                    width: "8px"
                  }
                }), React.createElement(Material.IconButton, {
                  children: React.createElement(Material.Avatar, tmp),
                  color: "inherit",
                  onClick: handleMenu
                }), React.createElement(Material.Menu, {
                  children: React.createElement(Material.MenuItem, {
                        children: React.createElement(ReactRouterDom.Link, {
                              children: "Deposit",
                              style: {
                                textDecoration: "none"
                              },
                              to: "/deposit"
                            })
                      }),
                  open: isOpen,
                  id: "menu-appbar",
                  anchorEl: anchorEl,
                  anchorOrigin: origin,
                  transformOrigin: origin,
                  keepMounted: true,
                  onClose: handleClose
                }));
}

var RightSide = {
  asyncOptionMap: asyncOptionMap,
  make: ApplicationBar$RightSide
};

var make = StyledComponents.StyledAppBar;

var StyledAppBar = {
  make: make
};

var make$1 = StyledComponents.StyledAppDrawer;

var StyledAppDrawer = {
  make: make$1
};

function ApplicationBar$AppBar(Props) {
  var isOpen = Props.isOpen;
  var onClick = Props.onClick;
  var title = Props.title;
  var iconButtonStyle = isOpen ? ({
        display: "none",
        marginRight: "36px"
      }) : ({
        marginRight: "36px"
      });
  return React.createElement(make, {
              children: React.createElement(Material.Toolbar, {
                    children: null
                  }, React.createElement(Material.IconButton, {
                        children: React.createElement(Menu, {}),
                        color: "inherit",
                        edge: "start",
                        sx: iconButtonStyle,
                        onClick: onClick
                      }), React.createElement(Material.Typography, {
                        children: title,
                        sx: {
                          flexGrow: "1"
                        },
                        variant: "h6",
                        component: "h1",
                        color: "inherit",
                        noWrap: true
                      }), React.createElement(ApplicationBar$RightSide, {})),
              open: isOpen,
              position: "absolute"
            });
}

var AppBar = {
  make: ApplicationBar$AppBar
};

function ApplicationBar$AppDrawer(Props) {
  var isOpen = Props.isOpen;
  var onClick = Props.onClick;
  var toolbarStyle = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    paddingRight: "1px",
    paddingLeft: "1px",
    alignItems: "center",
    justifyContent: "space-between"
  };
  var boxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
  };
  return React.createElement(make$1, {
              children: null,
              open: isOpen,
              anchor: "left",
              variant: "permanent"
            }, React.createElement(Material.Toolbar, {
                  children: null,
                  sx: toolbarStyle
                }, React.createElement(Material.Box, {
                      children: null,
                      sx: boxStyle
                    }, React.createElement(InhypedIcon.make, {
                          sx: {
                            marginRight: "12px",
                            marginLeft: "4px"
                          },
                          fontSize: "large"
                        }), React.createElement(Material.Typography, {
                          children: "Inhyped",
                          variant: "h6",
                          color: "black"
                        })), React.createElement(Material.IconButton, {
                      children: React.createElement(ChevronLeft, {}),
                      onClick: onClick
                    })), React.createElement(ApplicationBar$MenuItems, {}));
}

var AppDrawer = {
  make: ApplicationBar$AppDrawer
};

function ApplicationBar(Props) {
  var title = Props.title;
  var children = Props.children;
  var match = React.useContext(ApplicationBarContext.context);
  var setIsOpen = match.setIsOpen;
  var isOpen = match.isOpen;
  var toggleDrawer = function (param) {
    return Curry._1(setIsOpen, (function (prevIsOpen) {
                  return !prevIsOpen;
                }));
  };
  return React.createElement(Material.Box, {
              children: null,
              sx: {
                display: "flex"
              }
            }, React.createElement(ApplicationBar$AppBar, {
                  isOpen: isOpen,
                  onClick: toggleDrawer,
                  title: title
                }), React.createElement(ApplicationBar$AppDrawer, {
                  isOpen: isOpen,
                  onClick: toggleDrawer
                }), React.createElement(Material.Box, {
                  children: null,
                  component: "main",
                  sx: {
                    height: "100vh",
                    overflow: "auto",
                    flexGrow: "1"
                  }
                }, React.createElement(Material.Toolbar, {}), children));
}

var make$2 = ApplicationBar;

export {
  UnstyledLink ,
  MenuItems ,
  UserBalance ,
  DepositIconButton ,
  RightSide ,
  StyledAppBar ,
  StyledAppDrawer ,
  AppBar ,
  AppDrawer ,
  make$2 as make,
  
}
/* make Not a pure module */
