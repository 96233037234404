// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Types from "../shared/Types.bs.js";

function nearTransactionUrl(networkId, hash) {
  var hash$1 = Types.NearTransactionHash.toString(hash);
  if (networkId) {
    return "https://explorer.testnet.near.org/transactions/" + hash$1;
  } else {
    return "https://explorer.near.org/transactions/" + hash$1;
  }
}

function nearAccountUrl(networkId, account) {
  var account$1 = Types.NearAccountId.toString(account);
  if (networkId) {
    return "https://explorer.testnet.near.org/accounts/" + account$1;
  } else {
    return "https://explorer.near.org/accounts/" + account$1;
  }
}

export {
  nearTransactionUrl ,
  nearAccountUrl ,
  
}
/* Types Not a pure module */
