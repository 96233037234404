// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Near from "../utils/Near.bs.js";
import * as Types from "../shared/Types.bs.js";
import * as React from "react";
import * as ExternalLink from "./ExternalLink.bs.js";

function LinkToNearTransaction(Props) {
  var networkId = Props.networkId;
  var hash = Props.hash;
  var url = Near.nearTransactionUrl(networkId, hash);
  var hash$1 = Types.NearTransactionHash.toString(hash);
  return React.createElement(ExternalLink.make, {
              children: hash$1,
              href: url
            });
}

var make = LinkToNearTransaction;

export {
  make ,
  
}
/* Near Not a pure module */
