// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Tweet from "../../utils/Tweet.bs.js";
import * as Types from "../../shared/Types.bs.js";
import * as Utils from "../../shared/Utils.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as SkeletonCardList from "../../components/SkeletonCardList.bs.js";
import * as ReactRouterDom from "react-router-dom";

function renderMyTask(taskWithTweet) {
  var task = taskWithTweet.task;
  return React.createElement(Material.Card, {
              children: null
            }, React.createElement(Material.CardContent, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "Reward: " + Format.formatNearAmount4(task.contractorReward),
                      color: "text.secondary"
                    }), React.createElement(Material.Typography, {
                      children: "Status: " + Types.TaskStatusView.display(task.status),
                      color: "text.secondary"
                    }), React.createElement(Material.Typography, {
                      children: Tweet.shortenTweetText(taskWithTweet.tweet.text),
                      variant: "body2",
                      component: "p"
                    })), React.createElement(Material.CardActions, {
                  children: React.createElement(Material.Button, {
                        children: React.createElement(ReactRouterDom.Link, {
                              children: "Details",
                              to: "/tasks/" + Types.TaskId.toString(task.id)
                            }),
                        size: "small"
                      })
                }));
}

function renderTaskList(tasks) {
  var taskItems = Belt_Array.map(tasks, (function (task) {
          var key = Types.TaskId.toString(task.task.id);
          return React.createElement(Material.Grid, {
                      children: renderMyTask(task),
                      item: true,
                      xs: 12,
                      md: 6,
                      lg: 4,
                      key: key
                    });
        }));
  var boxStyle = {
    flexGrow: "1"
  };
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: taskItems,
                    container: true,
                    spacing: 2
                  }),
              sx: boxStyle
            });
}

function sortByCreatedAtDesc(tasks) {
  return Belt_Array.reverse(Utils.Sort.sortBy(tasks, Utils.Cmp.string, (function (t) {
                    return t.task.createdAt;
                  })));
}

function ListMyTasksPage$MainContent(Props) {
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setMyTasks = match[1];
  var myTasks = match[0];
  React.useEffect((function () {
          Api.getMyTasks(undefined).then(function (tasks) {
                return Promise.resolve(Curry._1(setMyTasks, (function (param) {
                                  return /* Done */{
                                          _0: sortByCreatedAtDesc(tasks)
                                        };
                                })));
              });
          
        }), []);
  if (typeof myTasks === "number") {
    return React.createElement(SkeletonCardList.make, {});
  } else {
    return renderTaskList(myTasks._0);
  }
}

var MainContent = {
  renderMyTask: renderMyTask,
  renderTaskList: renderTaskList,
  sortByCreatedAtDesc: sortByCreatedAtDesc,
  make: ListMyTasksPage$MainContent
};

function ListMyTasksPage(Props) {
  return React.createElement(ApplicationBar.make, {
              title: "Task",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "New tasks",
                            to: "/tasks/claimable"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Past tasks",
                            to: "/tasks/my"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "My Tasks",
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true
                    }), React.createElement(ListMyTasksPage$MainContent, {})));
}

var make = ListMyTasksPage;

export {
  MainContent ,
  make ,
  
}
/* Api Not a pure module */
