// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../hooks/Hooks.bs.js";
import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var ContextError = /* @__PURE__ */Caml_exceptions.create("ApplicationBarContext.ContextError");

var context = React.createContext({
      isOpen: true,
      setIsOpen: (function (param) {
          throw {
                RE_EXN_ID: ContextError,
                _1: "ApplicationBarContext is not yet initialized",
                Error: new Error()
              };
        })
    });

var provider = context.Provider;

function ApplicationBarContext$Provider(Props) {
  var children = Props.children;
  var match = Hooks.useLocalStorage("inhypedAppBarOpen", true, Json_decode.bool, (function (prim) {
          return prim;
        }));
  var ctx_isOpen = match[0];
  var ctx_setIsOpen = match[1];
  var ctx = {
    isOpen: ctx_isOpen,
    setIsOpen: ctx_setIsOpen
  };
  return React.createElement(provider, {
              value: ctx,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: ApplicationBarContext$Provider
};

export {
  ContextError ,
  context ,
  Provider ,
  
}
/* context Not a pure module */
