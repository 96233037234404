// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NearApiJs from "near-api-js";

function make(account, contractName) {
  var methods_changeMethods = ["deposit"];
  var methods_viewMethods = [];
  var methods = {
    changeMethods: methods_changeMethods,
    viewMethods: methods_viewMethods
  };
  var nearContract = new NearApiJs.Contract(account, contractName, methods);
  return /* Contract */{
          _0: nearContract
        };
}

function deposit(contract, token, amount) {
  var args = {
    token: token
  };
  return contract._0.deposit(args, "30000000000000", amount);
}

export {
  make ,
  deposit ,
  
}
/* near-api-js Not a pure module */
