// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Big from "../../bindings/Big.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as Lab from "@mui/lab";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../contexts/UserContext.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as ReactRouterDom from "react-router-dom";
import * as LinkToNearAccount from "../../components/LinkToNearAccount.bs.js";
import * as LinkToNearTransaction from "../../components/LinkToNearTransaction.bs.js";

function emptyFormParams(param) {
  return {
          recipient: "",
          amount: ""
        };
}

function emptyFormErrors(param) {
  return {
          recipient: undefined,
          amount: undefined
        };
}

function validateRecipient(recipient, networkId) {
  var recipient$1 = recipient.trim();
  if (recipient$1 === "") {
    return {
            TAG: /* Error */1,
            _0: "Cannot be empty"
          };
  }
  var regex = networkId ? /^[a-zA-Z0-9-_]+\.testnet$|^[0-9a-f]{64}$/ : /^[a-zA-Z0-9-_]+\.near$|^[0-9a-f]{64}$/;
  var match = regex.exec(recipient$1);
  if (match !== null) {
    return {
            TAG: /* Ok */0,
            _0: Types.NearAccountId.fromString(recipient$1)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Is invalid"
          };
  }
}

function getError(result) {
  if (result.TAG === /* Ok */0) {
    return ;
  } else {
    return Caml_option.some(result._0);
  }
}

function validateAmount(amount, availableBalance) {
  var amount$1 = amount.trim();
  if (amount$1 === "") {
    return {
            TAG: /* Error */1,
            _0: "Cannot be empty"
          };
  }
  var amount$2 = Big.parse(amount$1);
  if (amount$2 === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Is invalid"
          };
  }
  var amount$3 = Caml_option.valFromOption(amount$2);
  if (amount$3.lt(Big.fromString("0.1"))) {
    return {
            TAG: /* Error */1,
            _0: "The minimal amount for withdrawal is 0.1 NEAR"
          };
  } else if (amount$3.gt(Big.fromString("20"))) {
    return {
            TAG: /* Error */1,
            _0: "The maximum amount for withdrawal is 20 NEAR"
          };
  } else if (amount$3.gt(availableBalance)) {
    return {
            TAG: /* Error */1,
            _0: "You have only " + Format.formatNearAmount4(availableBalance) + " available"
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: amount$3
          };
  }
}

function convertWithdrawErrorViewToFormErrors(error) {
  if (typeof error === "number") {
    return {
            recipient: "Is invalid",
            amount: undefined
          };
  }
  switch (error.TAG | 0) {
    case /* RecipientAccountDoesNotExist */0 :
        return {
                recipient: "Account " + Types.NearAccountId.toString(error.recipientAccountId) + " does not exist",
                amount: undefined
              };
    case /* RequestedAmountTooSmall */1 :
        return {
                recipient: undefined,
                amount: "The minimal amount for withdrawal is " + Format.formatNearAmount4(error.minAmount)
              };
    case /* RequestedAmountTooHigh */2 :
        return {
                recipient: undefined,
                amount: "The maximum amount for withdrawal is " + Format.formatNearAmount4(error.maxAmount)
              };
    case /* InsufficientFunds */3 :
        return {
                recipient: undefined,
                amount: "Available balance is " + Format.formatNearAmount4(error.availableBalance) + ", what is not sufficient"
              };
    
  }
}

function formatWithdrawError(error) {
  if (typeof error === "number") {
    return "Recipient is invalid";
  }
  switch (error.TAG | 0) {
    case /* RecipientAccountDoesNotExist */0 :
        return "Recipient account " + Types.NearAccountId.toString(error.recipientAccountId) + " does not exist";
    case /* RequestedAmountTooSmall */1 :
        return "The minimal amount for withdrawal is " + Format.formatNearAmount4(error.minAmount) + ", but was requested only " + Format.formatNearAmount4(error.requestedAmount);
    case /* RequestedAmountTooHigh */2 :
        return "The maximum amount for withdrawal is " + Format.formatNearAmount4(error.maxAmount) + ", but was requested " + Format.formatNearAmount4(error.requestedAmount);
    case /* InsufficientFunds */3 :
        return "Available balance is " + Format.formatNearAmount4(error.availableBalance) + ", what is not sufficient to withdraw " + Format.formatNearAmount4(error.requestedAmount);
    
  }
}

function WithdrawPage$Success(Props) {
  var response = Props.response;
  var nearNetworkId = Props.nearNetworkId;
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Alert, {
                    children: null,
                    severity: "success"
                  }, React.createElement(Material.AlertTitle, {
                        children: "Success! 🎉🎉🎉 "
                      }), React.createElement("p", undefined, "Amount of " + Format.formatNearAmount4(response.amount) + " is withdrawn to ", React.createElement(LinkToNearAccount.make, {
                            networkId: nearNetworkId,
                            account: response.recipientNearAccountId
                          })), React.createElement("p", undefined, "Transaction ", React.createElement(LinkToNearTransaction.make, {
                            networkId: nearNetworkId,
                            hash: response.nearTransactionHash
                          })))
            });
}

var Success = {
  make: WithdrawPage$Success
};

function validate(params, networkId, availableBalance) {
  var recipientResult = validateRecipient(params.recipient, networkId);
  var amountResult = validateAmount(params.amount, availableBalance);
  if (recipientResult.TAG === /* Ok */0 && amountResult.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              recipientNearAccountId: recipientResult._0,
              amount: amountResult._0
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              recipient: getError(recipientResult),
              amount: getError(amountResult)
            }
          };
  }
}

function helperTextOrError(helperText, error) {
  if (error !== undefined) {
    return React.createElement(Material.FormHelperText, {
                children: React.createElement("b", undefined, error)
              });
  } else {
    return React.createElement(Material.FormHelperText, {
                children: helperText
              });
  }
}

function WithdrawPage$WithdrawForm(Props) {
  var nearNetworkId = Props.nearNetworkId;
  var availableBalance = Props.availableBalance;
  var match = React.useContext(UserContext.context);
  var reloadUser = match.reloadUser;
  var match$1 = React.useState(function () {
        return {
                recipient: "",
                amount: ""
              };
      });
  var setFormParams = match$1[1];
  var formParams = match$1[0];
  var match$2 = React.useState(function () {
        return {
                recipient: undefined,
                amount: undefined
              };
      });
  var setFormErrors = match$2[1];
  var formErrors = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotAsked */0;
      });
  var setWithdrawResponse = match$3[1];
  var withdrawResponse = match$3[0];
  var handleRecipientChange = function ($$event) {
    var recipient = $$event.target.value;
    return Curry._1(setFormParams, (function (oldParams) {
                  return {
                          recipient: recipient,
                          amount: oldParams.amount
                        };
                }));
  };
  var handleAmountChange = function ($$event) {
    var amount = $$event.target.value;
    return Curry._1(setFormParams, (function (oldParams) {
                  return {
                          recipient: oldParams.recipient,
                          amount: amount
                        };
                }));
  };
  var handleSubmit = function (param) {
    var validationResult = validate(formParams, nearNetworkId, availableBalance);
    if (validationResult.TAG === /* Ok */0) {
      Curry._1(setFormErrors, (function (param) {
              return {
                      recipient: undefined,
                      amount: undefined
                    };
            }));
      Curry._1(setWithdrawResponse, (function (param) {
              return /* Loading */1;
            }));
      Api.withdraw(validationResult._0).then(function (withdrawResult) {
            if (withdrawResult.TAG === /* Ok */0) {
              var resp = withdrawResult._0;
              Curry._1(setWithdrawResponse, (function (param) {
                      return /* Done */{
                              _0: {
                                TAG: /* Ok */0,
                                _0: resp
                              }
                            };
                    }));
              Curry._1(reloadUser, undefined);
            } else {
              var error = withdrawResult._0;
              Curry._1(setFormErrors, (function (param) {
                      return convertWithdrawErrorViewToFormErrors(error);
                    }));
              var errMsg = formatWithdrawError(error);
              Curry._1(setWithdrawResponse, (function (param) {
                      return /* Done */{
                              _0: {
                                TAG: /* Error */1,
                                _0: errMsg
                              }
                            };
                    }));
            }
            return Promise.resolve(undefined);
          });
      return ;
    }
    var errors = validationResult._0;
    return Curry._1(setFormErrors, (function (param) {
                  return errors;
                }));
  };
  var renderForm = function (isLoading, commonError) {
    var errorGridItem = commonError !== undefined ? React.createElement(Material.Grid, {
            children: React.createElement(Material.Alert, {
                  children: null,
                  severity: "error"
                }, React.createElement(Material.AlertTitle, {
                      children: "Withdrawal failed"
                    }), commonError),
            item: true,
            xs: 12
          }) : null;
    return React.createElement(Material.Box, {
                children: React.createElement(Material.Grid, {
                      children: null,
                      container: true,
                      spacing: 2
                    }, React.createElement(Material.Grid, {
                          children: null,
                          item: true,
                          xs: 12
                        }, React.createElement(Material.FormControl, {
                              children: React.createElement(Material.TextField, {
                                    label: "Recipient account",
                                    value: formParams.recipient,
                                    onChange: handleRecipientChange,
                                    fullWidth: true,
                                    required: true,
                                    id: "recipient",
                                    disabled: isLoading,
                                    error: Belt_Option.isSome(formErrors.recipient)
                                  }),
                              fullWidth: true
                            }), helperTextOrError("NEAR account address (e.g. vasya.near)", formErrors.recipient)), React.createElement(Material.Grid, {
                          children: null,
                          item: true,
                          xs: 12
                        }, React.createElement(Material.FormControl, {
                              children: React.createElement(Material.TextField, {
                                    label: "Amount",
                                    value: formParams.amount,
                                    onChange: handleAmountChange,
                                    fullWidth: true,
                                    required: true,
                                    id: "amount",
                                    disabled: isLoading,
                                    error: Belt_Option.isSome(formErrors.amount)
                                  }),
                              fullWidth: true
                            }), helperTextOrError("You have " + Format.formatNearAmount4(availableBalance) + " available for withdrawal", formErrors.amount)), errorGridItem, React.createElement(Material.Grid, {
                          children: React.createElement(Material.Grid, {
                                children: React.createElement(Lab.LoadingButton, {
                                      children: "Withdraw",
                                      onClick: handleSubmit,
                                      variant: "contained",
                                      color: "primary",
                                      disabled: isLoading,
                                      loading: isLoading
                                    }),
                                container: true,
                                justifyContent: "center"
                              }),
                          item: true,
                          xs: 12
                        })),
                sx: {
                  flexGrow: "1"
                }
              });
  };
  if (typeof withdrawResponse === "number") {
    if (withdrawResponse !== 0) {
      return renderForm(true, undefined);
    } else {
      return renderForm(false, undefined);
    }
  }
  var errMsg = withdrawResponse._0;
  if (errMsg.TAG === /* Ok */0) {
    return React.createElement(WithdrawPage$Success, {
                response: errMsg._0,
                nearNetworkId: nearNetworkId
              });
  } else {
    return renderForm(false, errMsg._0);
  }
}

var WithdrawForm = {
  helperTextOrError: helperTextOrError,
  make: WithdrawPage$WithdrawForm
};

function WithdrawPage(Props) {
  var nearNetworkId = Props.nearNetworkId;
  var availableBalance = Props.availableBalance;
  return React.createElement(ApplicationBar.make, {
              title: "Withdraw NEAR",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "Transactions",
                            to: "/transactions/my"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Deposit",
                            to: "/deposit"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Withdraw",
                            to: "/withdraw"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: "Withdraw NEAR",
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true,
                      color: "text.primary"
                    }), React.createElement(WithdrawPage$WithdrawForm, {
                      nearNetworkId: nearNetworkId,
                      availableBalance: availableBalance
                    })));
}

var make = WithdrawPage;

export {
  emptyFormParams ,
  emptyFormErrors ,
  validateRecipient ,
  getError ,
  validateAmount ,
  convertWithdrawErrorViewToFormErrors ,
  formatWithdrawError ,
  Success ,
  validate ,
  WithdrawForm ,
  make ,
  
}
/* Api Not a pure module */
