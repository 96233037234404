// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as BigJs from "big.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function fromFloat(prim) {
  return BigJs.Big(prim);
}

function fromString(prim) {
  return BigJs.Big(prim);
}

function parse(value) {
  try {
    return Caml_option.some(BigJs.Big(value));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      return ;
    }
    throw exn;
  }
}

export {
  fromFloat ,
  fromString ,
  parse ,
  
}
/* big.js Not a pure module */
