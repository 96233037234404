// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";

var context = React.createContext({
      user: /* Loading */1,
      reloadUser: (function (param) {
          
        })
    });

var provider = context.Provider;

function UserContext$Provider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setUser = match[1];
  var reloadUser = React.useCallback((function (param) {
          $$Promise.$$catch(Api.getSeed(undefined).then(function (seedData) {
                    var currentUser = seedData.currentUser;
                    Curry._1(setUser, (function (param) {
                            return /* Done */{
                                    _0: currentUser
                                  };
                          }));
                    return Promise.resolve(undefined);
                  }), (function (err) {
                  console.error("Error on attempt to get seed data: ", err);
                  Curry._1(setUser, (function (param) {
                          return /* Done */{
                                  _0: undefined
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), []);
  React.useEffect((function () {
          Curry._1(reloadUser, undefined);
          
        }), []);
  var value_user = match[0];
  var value = {
    user: value_user,
    reloadUser: reloadUser
  };
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: UserContext$Provider
};

export {
  context ,
  Provider ,
  
}
/* context Not a pure module */
