// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as HomePage from "./pages/HomePage.bs.js";
import * as MyTaskPage from "./pages/tasks/MyTaskPage.bs.js";
import * as DepositPage from "./pages/transactions/DepositPage.bs.js";
import * as UserContext from "./contexts/UserContext.bs.js";
import * as NewOrderPage from "./pages/orders/NewOrderPage.bs.js";
import * as WithdrawPage from "./pages/transactions/WithdrawPage.bs.js";
import * as DashboardPage from "./pages/DashboardPage.bs.js";
import * as ListMyTasksPage from "./pages/tasks/ListMyTasksPage.bs.js";
import * as ReactRouterDom from "react-router-dom";
import * as ListMyTransactions from "./pages/transactions/ListMyTransactions.bs.js";
import * as ListMyPastOrdersPage from "./pages/orders/ListMyPastOrdersPage.bs.js";
import * as ApplicationBarContext from "./contexts/ApplicationBarContext.bs.js";
import * as ListClaimableTasksPage from "./pages/tasks/ListClaimableTasksPage.bs.js";
import * as ListMyActiveOrdersPage from "./pages/orders/ListMyActiveOrdersPage.bs.js";

function App$AppRouter(Props) {
  var seed = Props.seed;
  var nearConfig = seed.nearConfig;
  var currentUser = seed.currentUser;
  return React.createElement(ReactRouterDom.BrowserRouter, {
              children: React.createElement(ReactRouterDom.Switch, {
                    children: null
                  }, React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(DepositPage.make, {
                              nearConfig: nearConfig
                            }),
                        path: "/deposit",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(WithdrawPage.make, {
                              nearNetworkId: nearConfig.networkId,
                              availableBalance: currentUser.balance
                            }),
                        path: "/withdraw",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(ListMyTransactions.make, {
                              nearNetworkId: nearConfig.networkId
                            }),
                        path: "/transactions/my",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(NewOrderPage.make, {}),
                        path: "/orders/new",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(ListMyActiveOrdersPage.make, {}),
                        path: "/orders/my/active",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(ListMyPastOrdersPage.make, {}),
                        path: "/orders/my/past",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(ListClaimableTasksPage.make, {}),
                        path: "/tasks/claimable",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(ListMyTasksPage.make, {}),
                        path: "/tasks/my",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(MyTaskPage.make, {}),
                        path: "/tasks/:taskId",
                        exact: true
                      }), React.createElement(ReactRouterDom.Route, {
                        children: React.createElement(DashboardPage.make, {
                              userName: currentUser.name
                            }),
                        path: "/",
                        exact: true
                      }))
            });
}

var AppRouter = {
  make: App$AppRouter
};

function App$Providers(Props) {
  var children = Props.children;
  return React.createElement(UserContext.Provider.make, {
              children: React.createElement(ApplicationBarContext.Provider.make, {
                    children: children
                  })
            });
}

var Providers = {
  make: App$Providers
};

function App(Props) {
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setSeed = match[1];
  var seed = match[0];
  var loadMainState = React.useCallback((function (param) {
          $$Promise.$$catch(Api.getSeed(undefined).then(function (seed) {
                    Curry._1(setSeed, (function (param) {
                            return /* Done */{
                                    _0: {
                                      TAG: /* Ok */0,
                                      _0: seed
                                    }
                                  };
                          }));
                    return Promise.resolve(undefined);
                  }), (function (_err) {
                  Curry._1(setSeed, (function (param) {
                          return /* Done */{
                                  _0: {
                                    TAG: /* Error */1,
                                    _0: undefined
                                  }
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), []);
  React.useEffect((function () {
          Curry._1(loadMainState, undefined);
          
        }), [loadMainState]);
  var tmp;
  if (typeof seed === "number") {
    tmp = React.createElement(HomePage.make, {
          state: "loading"
        });
  } else {
    var seed$1 = seed._0;
    tmp = seed$1.TAG === /* Ok */0 ? React.createElement(App$AppRouter, {
            seed: seed$1._0
          }) : React.createElement(HomePage.make, {
            state: "notLoggedIn"
          });
  }
  return React.createElement(App$Providers, {
              children: tmp
            });
}

var make = App;

export {
  AppRouter ,
  Providers ,
  make ,
  
}
/* Api Not a pure module */
