// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Tweet from "../../utils/Tweet.bs.js";
import * as Types from "../../shared/Types.bs.js";
import * as Utils from "../../shared/Utils.bs.js";
import * as React from "react";
import * as Format from "../../utils/Format.bs.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as UserContext from "../../contexts/UserContext.bs.js";
import * as ExternalLink from "../../components/ExternalLink.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as SkeletonCardList from "../../components/SkeletonCardList.bs.js";
import * as ReactRouterDom from "react-router-dom";
import * as ButtonWithConfirmation from "../../components/ButtonWithConfirmation.bs.js";
import TaskOutlined from "@mui/icons-material/TaskOutlined";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

function ListMyOrdersPage$ProgressChip(Props) {
  var performed = Props.performed;
  var total = Props.total;
  var label = String(performed) + " / " + String(total);
  var icon = React.createElement(TaskOutlined, {});
  var tooltipText = String(performed) + " out of " + String(total) + " tasks are performed";
  return React.createElement(Material.Tooltip, {
              children: React.createElement(Material.Chip, {
                    label: label,
                    size: "small",
                    icon: icon
                  }),
              arrow: true,
              title: tooltipText
            });
}

var ProgressChip = {
  make: ListMyOrdersPage$ProgressChip
};

function statusToString(status) {
  if (typeof status === "number") {
    return "Active";
  }
  switch (status.TAG | 0) {
    case /* Fulfilled */0 :
        return "Fulfilled";
    case /* Canceled */1 :
        return "Canceled";
    case /* Expired */2 :
        return " Expired";
    
  }
}

function ListMyOrdersPage$MyRetweetOrder(Props) {
  var extOrder = Props.extOrder;
  var reloadOrders = Props.reloadOrders;
  var tweet = extOrder.tweet;
  var retweetOrder = extOrder.retweetOrder;
  var match = React.useContext(UserContext.context);
  var reloadUser = match.reloadUser;
  var cancelOrder = function (param) {
    Api.cancelRetweetOrder(retweetOrder.id).then(function (param) {
          Curry._1(reloadUser, undefined);
          Curry._1(reloadOrders, undefined);
          return Promise.resolve(undefined);
        });
    
  };
  var actionsStyle = {
    justifyContent: "space-between"
  };
  return React.createElement(Material.Card, {
              children: null,
              style: {
                width: "100%"
              }
            }, React.createElement(Material.CardContent, {
                  children: null
                }, React.createElement(Material.Grid, {
                      children: null,
                      container: true
                    }, React.createElement(Material.Grid, {
                          children: null,
                          item: true,
                          xs: 8
                        }, React.createElement(Material.Typography, {
                              children: "Budget: " + Format.formatNearAmount4(retweetOrder.budget),
                              style: {
                                whiteSpace: "nowrap"
                              },
                              color: "text.secondary"
                            }), React.createElement(Material.Typography, {
                              children: "Status: " + statusToString(retweetOrder.status),
                              style: {
                                whiteSpace: "nowrap"
                              },
                              color: "text.secondary"
                            })), React.createElement(Material.Grid, {
                          children: React.createElement(Material.Grid, {
                                children: React.createElement(ListMyOrdersPage$ProgressChip, {
                                      performed: extOrder.details.numberOfTasksPerformed,
                                      total: retweetOrder.numberOfTasks
                                    }),
                                container: true,
                                justifyContent: "flex-end"
                              }),
                          item: true,
                          xs: 4
                        })), React.createElement(Material.Typography, {
                      children: Tweet.shortenTweetText(tweet.text),
                      variant: "body2",
                      component: "p"
                    })), React.createElement(Material.CardActions, {
                  children: null,
                  style: actionsStyle
                }, React.createElement(ButtonWithConfirmation.make, {
                      children: "Cancel",
                      onConfirm: cancelOrder,
                      cancelText: "Back",
                      title: "Cancel order",
                      disabled: !Types.MyRetweetOrderView.isActive(retweetOrder),
                      content: "Are you sure you want to cancel the order?"
                    }), React.createElement(ExternalLink.make, {
                      children: "Open Tweet",
                      href: Tweet.tweetUrl(tweet.id)
                    })));
}

var MyRetweetOrder = {
  statusToString: statusToString,
  make: ListMyOrdersPage$MyRetweetOrder
};

function tap(x, f) {
  Curry._1(f, x);
  return x;
}

function renderOrderList(orders, reloadOrders) {
  var orderElements = Belt_Array.map(orders, (function (order) {
          var key = Types.RetweetOrderId.toString(order.retweetOrder.id);
          return React.createElement(Material.Grid, {
                      children: React.createElement(ListMyOrdersPage$MyRetweetOrder, {
                            extOrder: order,
                            reloadOrders: reloadOrders
                          }),
                      style: {
                        width: "100%"
                      },
                      item: true,
                      xs: 12,
                      md: 6,
                      lg: 4,
                      key: key
                    });
        }));
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: orderElements,
                    container: true,
                    spacing: 2
                  }),
              sx: {
                flexGrow: "1"
              }
            });
}

function sortByCreatedAtDesc(orders) {
  return Belt_Array.reverse(Utils.Sort.sortBy(orders, Utils.Cmp.string, (function (o) {
                    return o.retweetOrder.createdAt;
                  })));
}

function ListMyOrdersPage$MainContent(Props) {
  var filter = Props.filter;
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setOrders = match[1];
  var orders = match[0];
  var reloadOrders = React.useCallback(function (param) {
        $$Promise.$$catch(Api.getMyRetweetOrders(undefined).then(function (orders) {
                  return Promise.resolve(Curry._1(setOrders, (function (param) {
                                    return /* Done */{
                                            _0: {
                                              TAG: /* Ok */0,
                                              _0: sortByCreatedAtDesc(Belt_Array.keep(orders, filter))
                                            }
                                          };
                                  })));
                }), (function (err) {
                console.log(err);
                return Promise.resolve(Curry._1(setOrders, (function (param) {
                                  return /* Done */{
                                          _0: {
                                            TAG: /* Error */1,
                                            _0: err
                                          }
                                        };
                                })));
              }));
        
      });
  React.useEffect((function () {
          Curry._1(reloadOrders, undefined);
          
        }), []);
  if (typeof orders === "number") {
    return React.createElement(SkeletonCardList.make, {});
  }
  var orders$1 = orders._0;
  if (orders$1.TAG === /* Ok */0) {
    return renderOrderList(orders$1._0, reloadOrders);
  } else {
    return React.createElement(React.Fragment, undefined, "Ooops. Something went wront");
  }
}

var MainContent = {
  tap: tap,
  renderOrderList: renderOrderList,
  sortByCreatedAtDesc: sortByCreatedAtDesc,
  make: ListMyOrdersPage$MainContent
};

function ListMyOrdersPage(Props) {
  var title = Props.title;
  var filter = Props.filter;
  return React.createElement(ApplicationBar.make, {
              title: title,
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: React.createElement(Material.Button, {
                                  children: React.createElement("div", {
                                        style: {
                                          display: "flex",
                                          alignItems: "center",
                                          flexWrap: "wrap"
                                        }
                                      }, React.createElement(AddCircleOutline, {}), React.createElement("span", {
                                            style: {
                                              marginLeft: "4px"
                                            }
                                          }, "New Order")),
                                  variant: "contained",
                                  color: "primary"
                                }),
                            style: {
                              textDecoration: "none"
                            },
                            to: "/orders/new"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Active Orders",
                            to: "/orders/my/active"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Past Orders",
                            to: "/orders/my/past"
                          }))
                }), React.createElement(Material.Container, {
                  children: null
                }, React.createElement(Material.Typography, {
                      children: title,
                      variant: "h4",
                      component: "h1",
                      align: "center",
                      gutterBottom: true
                    }), React.createElement(ListMyOrdersPage$MainContent, {
                      filter: filter
                    })));
}

var make = ListMyOrdersPage;

export {
  ProgressChip ,
  MyRetweetOrder ,
  MainContent ,
  make ,
  
}
/* Api Not a pure module */
