// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as InhypedIcon from "../icons/InhypedIcon.bs.js";
import * as Material from "@mui/material";

function renderHelloContent(param) {
  var inhypedIconStyle = {
    height: "100px",
    marginRight: "4px",
    position: "relative",
    top: "12px",
    width: "100px"
  };
  return React.createElement(Material.Container, {
              children: null,
              maxWidth: "sm"
            }, React.createElement(Material.Toolbar, {}), React.createElement(Material.Typography, {
                  children: null,
                  variant: "h2",
                  component: "h1",
                  align: "center",
                  gutterBottom: true
                }, React.createElement(InhypedIcon.make, {
                      sx: inhypedIconStyle
                    }), "Inhyped"), React.createElement(Material.Typography, {
                  children: "Easy way to promote your tweets or earn crypto by using your twitter account.",
                  variant: "h5",
                  align: "center",
                  color: "textSecondary",
                  paragraph: true
                }), React.createElement(Material.Grid, {
                  children: React.createElement(Material.Grid, {
                        children: React.createElement("a", {
                              href: "/api/login/twitter/start",
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, React.createElement(Material.Button, {
                                  children: "Continue with Twitter",
                                  variant: "contained",
                                  color: "primary"
                                })),
                        item: true
                      }),
                  container: true,
                  spacing: 2,
                  justifyContent: "center"
                }));
}

function HomePage(Props) {
  var state = Props.state;
  if (state === "notLoggedIn") {
    return renderHelloContent(undefined);
  } else {
    return React.createElement(React.Fragment, undefined);
  }
}

var make = HomePage;

export {
  renderHelloContent ,
  make ,
  
}
/* react Not a pure module */
