// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Big from "../bindings/Big.bs.js";
import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function decodeDict(json) {
  var value = Js_json.classify(json);
  if (typeof value === "number") {
    throw {
          RE_EXN_ID: Json_decode.DecodeError,
          _1: "Expected to be a dictionary: " + Json.stringify(json),
          Error: new Error()
        };
  }
  if (value.TAG === /* JSONObject */2) {
    return value._0;
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Expected to be a dictionary: " + Json.stringify(json),
        Error: new Error()
      };
}

function pickTag(json) {
  var tagJson = Js_dict.get(decodeDict(json), "tag");
  if (tagJson !== undefined) {
    return Json_decode.string(Caml_option.valFromOption(tagJson));
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Expected to have field \"tag\" on: " + Json.stringify(json),
        Error: new Error()
      };
}

function pickContent(json) {
  var contentJson = Js_dict.get(decodeDict(json), "content");
  if (contentJson !== undefined) {
    return Caml_option.valFromOption(contentJson);
  }
  throw {
        RE_EXN_ID: Json_decode.DecodeError,
        _1: "Expected to have field \"content\" on: " + Json.stringify(json),
        Error: new Error()
      };
}

function decodeBig(json) {
  return Big.fromString(Json_decode.string(json));
}

function encodeBig(big) {
  return big.toString();
}

var D;

var E;

export {
  D ,
  E ,
  pickTag ,
  pickContent ,
  decodeBig ,
  encodeBig ,
  
}
/* Big Not a pure module */
