// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../shared/Api.bs.js";
import * as Big from "../../bindings/Big.bs.js";
import * as Near from "../../utils/Near.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Types from "../../shared/Types.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as NearApi from "../../bindings/NearApi.bs.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../contexts/UserContext.bs.js";
import * as NearApiJs from "near-api-js";
import * as ExternalLink from "../../components/ExternalLink.bs.js";
import * as Material from "@mui/material";
import * as ApplicationBar from "../../components/ApplicationBar.bs.js";
import * as InhypedContract from "../../contracts/InhypedContract.bs.js";
import * as ReactRouterDom from "react-router-dom";

function emptyFormParams(param) {
  return {
          amount: ""
        };
}

function emptyformErrors(param) {
  return {
          amount: undefined
        };
}

function validateAmount(amount) {
  var amount$1 = Big.parse(amount);
  if (amount$1 === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Invalid"
          };
  }
  var amount$2 = Caml_option.valFromOption(amount$1);
  if (amount$2.gt(Big.fromString("1000"))) {
    return {
            TAG: /* Error */1,
            _0: "The maximum amount is 1000 NEAR"
          };
  } else if (amount$2.lt(Big.fromString("0.01"))) {
    return {
            TAG: /* Error */1,
            _0: "The minimal amount is 0.1 NEAR"
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: amount$2
          };
  }
}

function validate(formParams) {
  var amountResult = validateAmount(formParams.amount);
  if (amountResult.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              amount: amountResult._0
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              amount: amountResult._0
            }
          };
  }
}

function setupNearContext(config) {
  var contractName = config.contractName;
  var keyStore = new (NearApiJs.keyStores.BrowserLocalStorageKeyStore)();
  var conf = NearApi.NearConfig.make(Types.NearNetworkId.toString(config.networkId), config.nodeUrl, Js_dict.fromArray([]), config.walletUrl, Caml_option.some(keyStore), config.helperUrl, undefined, undefined, undefined);
  return NearApiJs.connect(conf).then(function (near) {
              var walletConnection = new NearApiJs.WalletConnection(near, undefined);
              var account = walletConnection.account();
              var contract = InhypedContract.make(account, contractName);
              return Promise.resolve({
                          config: config,
                          near: near,
                          walletConnection: walletConnection,
                          contract: contract
                        });
            });
}

function getQueryParam(paramName) {
  var searchParamsStr = window.location.search;
  var searchParams = new URLSearchParams(searchParamsStr);
  return Caml_option.nullable_to_opt(searchParams.get(paramName));
}

function DepositPage$ConnectNearWallet(Props) {
  var nearContext = Props.nearContext;
  var handler = function (param) {
    nearContext.walletConnection.requestSignIn(nearContext.config.contractName, "Inhyped");
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "To proceed please connect your NEAR account"), React.createElement(Material.Button, {
                  children: "Connect NEAR account",
                  onClick: handler,
                  variant: "contained",
                  color: "primary"
                }));
}

var ConnectNearWallet = {
  make: DepositPage$ConnectNearWallet
};

function DepositPage$DepositForm(Props) {
  var nearContext = Props.nearContext;
  var match = React.useState(function () {
        return {
                amount: ""
              };
      });
  var setFormParams = match[1];
  var formParams = match[0];
  var match$1 = React.useState(function () {
        return {
                amount: undefined
              };
      });
  var setFormErrors = match$1[1];
  var formErrors = match$1[0];
  var handleAmountChange = function ($$event) {
    var amount = $$event.target.value;
    return Curry._1(setFormParams, (function (param) {
                  return {
                          amount: amount
                        };
                }));
  };
  var helperTextOrError = function (helperText, error) {
    if (error !== undefined) {
      return React.createElement(Material.FormHelperText, {
                  children: React.createElement("b", undefined, error)
                });
    } else {
      return React.createElement(Material.FormHelperText, {
                  children: helperText
                });
    }
  };
  var handleClick = function (param) {
    var validParams = validate(formParams);
    if (validParams.TAG === /* Ok */0) {
      Api.createDepositRequest(validParams._0).then(function (depositRequest) {
            var amountInYoctoNear = depositRequest.amount.times(Big.fromString("1000000000000000000000000"));
            return InhypedContract.deposit(nearContext.contract, depositRequest.token, amountInYoctoNear.toFixed(0));
          });
      return ;
    }
    var errors = validParams._0;
    return Curry._1(setFormErrors, (function (param) {
                  return errors;
                }));
  };
  return React.createElement(Material.Box, {
              children: React.createElement(Material.Grid, {
                    children: null,
                    container: true,
                    spacing: 2
                  }, React.createElement(Material.Grid, {
                        children: React.createElement(Material.FormControl, {
                              children: null,
                              fullWidth: true
                            }, React.createElement(Material.TextField, {
                                  label: "Amount",
                                  value: formParams.amount,
                                  onChange: handleAmountChange,
                                  fullWidth: true,
                                  required: true,
                                  id: "amount",
                                  error: Belt_Option.isSome(formErrors.amount)
                                }), helperTextOrError("Amount of Ⓝ  you want to deposit", formErrors.amount)),
                        item: true,
                        xs: 12
                      }), React.createElement(Material.Grid, {
                        children: React.createElement(Material.Grid, {
                              children: React.createElement(Material.Tooltip, {
                                    children: React.createElement(Material.Button, {
                                          children: "Deposit with NEAR",
                                          onClick: handleClick,
                                          variant: "contained",
                                          color: "primary"
                                        }),
                                    title: "You will be redirected to NEAR wallet page to authorize the transfer."
                                  }),
                              container: true,
                              justifyContent: "center"
                            }),
                        item: true,
                        xs: 12
                      })),
              sx: {
                flexGrow: "1"
              }
            });
}

var DepositForm = {
  make: DepositPage$DepositForm
};

function DepositPage$FinalizeTransactionError(Props) {
  var transactionHash = Props.transactionHash;
  var nearNetworkId = Props.nearNetworkId;
  var transactionUrl = Near.nearTransactionUrl(nearNetworkId, transactionHash);
  var hash = Types.NearTransactionHash.toString(transactionHash);
  var transactionLink = React.createElement(ExternalLink.make, {
        children: hash,
        href: transactionUrl
      });
  var inhypedTwitterLink = React.createElement(ExternalLink.make, {
        children: "@Inhyped",
        href: "https://twitter.com/inhyped"
      });
  return React.createElement(Material.Alert, {
              children: null,
              severity: "error"
            }, React.createElement(Material.AlertTitle, {
                  children: "Something went wrong 😔 😔 😔"
                }), React.createElement("p", undefined, "It looks like an error occurred while depositing. No panic. No penguin died and your assets are safe."), React.createElement("p", undefined, "Here is what to do:"), React.createElement("ul", undefined, React.createElement("li", undefined, "Try to reload the page by pressing F5 or Ctrl+R.")), React.createElement("p", undefined, "Do you still see this error?"), React.createElement("ul", undefined, React.createElement("li", undefined, "Check the transaction in Near Explorer and make sure it has succeeded: ", transactionLink), React.createElement("li", undefined, "Report the issue to us by sending a direct message with the URL of the transaction via Twitter: ", inhypedTwitterLink)));
}

var FinalizeTransactionError = {
  make: DepositPage$FinalizeTransactionError
};

function DepositPage$FinalizeTransaction(Props) {
  var transactionHash = Props.transactionHash;
  var nearNetworkId = Props.nearNetworkId;
  var match = React.useContext(UserContext.context);
  var reloadUser = match.reloadUser;
  var match$1 = React.useState(function () {
        return /* NotAsked */0;
      });
  var setDepositState = match$1[1];
  var depositState = match$1[0];
  React.useEffect((function () {
          $$Promise.$$catch(Api.finilizeDepositRequest({
                      transactionHash: transactionHash
                    }).then(function (param) {
                    Curry._1(setDepositState, (function (param) {
                            return /* Done */{
                                    _0: {
                                      TAG: /* Ok */0,
                                      _0: undefined
                                    }
                                  };
                          }));
                    Curry._1(reloadUser, undefined);
                    return Promise.resolve(undefined);
                  }), (function (err) {
                  console.error("Error on attempt to finalize deposit transaction: ", err);
                  Curry._1(setDepositState, (function (param) {
                          return /* Done */{
                                  _0: {
                                    TAG: /* Error */1,
                                    _0: undefined
                                  }
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), [
        transactionHash,
        reloadUser
      ]);
  if (typeof depositState === "number") {
    return React.createElement(React.Fragment, undefined, React.createElement(Material.CircularProgress, {
                    disableShrink: true
                  }), React.createElement("p", undefined, "Almost there! We are verifying your transaction... 🐧 🐧 🐧"));
  } else if (depositState._0.TAG === /* Ok */0) {
    return React.createElement(Material.Alert, {
                children: null,
                severity: "success"
              }, React.createElement(Material.AlertTitle, {
                    children: "Success! 🎉🎉🎉 "
                  }), React.createElement("p", undefined, "Congratulation! You successfully deposited!"), React.createElement("p", undefined, "Now you can ", React.createElement(ReactRouterDom.Link, {
                        children: "create orders to promote your tweets!",
                        to: "/orders/new"
                      })));
  } else {
    return React.createElement(DepositPage$FinalizeTransactionError, {
                transactionHash: transactionHash,
                nearNetworkId: nearNetworkId
              });
  }
}

var FinalizeTransaction = {
  make: DepositPage$FinalizeTransaction
};

function DepositPage$MainContent(Props) {
  var nearContext = Props.nearContext;
  var transactionHash = Belt_Option.map(getQueryParam("transactionHashes"), Types.NearTransactionHash.fromString);
  if (transactionHash !== undefined) {
    return React.createElement(DepositPage$FinalizeTransaction, {
                transactionHash: Caml_option.valFromOption(transactionHash),
                nearNetworkId: nearContext.config.networkId
              });
  } else if (nearContext.walletConnection.isSignedIn()) {
    return React.createElement(DepositPage$DepositForm, {
                nearContext: nearContext
              });
  } else {
    return React.createElement(DepositPage$ConnectNearWallet, {
                nearContext: nearContext
              });
  }
}

var MainContent = {
  make: DepositPage$MainContent
};

function DepositPage$MainWithNearSetup(Props) {
  var nearConfig = Props.nearConfig;
  var match = React.useState(function () {
        return /* Loading */1;
      });
  var setNearContext = match[1];
  var nearContext = match[0];
  React.useEffect((function () {
          $$Promise.$$catch(setupNearContext(nearConfig).then(function (ctx) {
                    Curry._1(setNearContext, (function (param) {
                            return /* Done */{
                                    _0: {
                                      TAG: /* Ok */0,
                                      _0: ctx
                                    }
                                  };
                          }));
                    return Promise.resolve(undefined);
                  }), (function (err) {
                  console.error(err);
                  Curry._1(setNearContext, (function (param) {
                          return /* Done */{
                                  _0: {
                                    TAG: /* Error */1,
                                    _0: err
                                  }
                                };
                        }));
                  return Promise.resolve(undefined);
                }));
          
        }), []);
  if (typeof nearContext === "number") {
    return React.createElement(Material.CircularProgress, {
                disableShrink: true
              });
  }
  var context = nearContext._0;
  if (context.TAG === /* Ok */0) {
    return React.createElement(DepositPage$MainContent, {
                nearContext: context._0
              });
  } else {
    return React.createElement(Material.Alert, {
                children: null,
                severity: "error"
              }, React.createElement(Material.AlertTitle, {
                    children: "Error"
                  }), "Could not connect to the smart contract.");
  }
}

var MainWithNearSetup = {
  make: DepositPage$MainWithNearSetup
};

function DepositPage(Props) {
  var nearConfig = Props.nearConfig;
  return React.createElement(ApplicationBar.make, {
              title: "Deposit NEAR",
              children: null
            }, React.createElement(Material.Toolbar, {
                  children: React.createElement(Material.Stack, {
                        children: null,
                        direction: "row",
                        spacing: 2
                      }, React.createElement(ReactRouterDom.Link, {
                            children: "Transactions",
                            to: "/transactions/my"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Deposit",
                            to: "/deposit"
                          }), React.createElement(ReactRouterDom.Link, {
                            children: "Withdraw",
                            to: "/withdraw"
                          }))
                }), React.createElement(Material.Container, {
                  children: React.createElement(Material.Grid, {
                        children: null,
                        container: true,
                        spacing: 0,
                        justifyContent: "center",
                        direction: "column",
                        alignItems: "center"
                      }, React.createElement(Material.Typography, {
                            children: "Deposit NEAR",
                            variant: "h4",
                            component: "h1",
                            align: "center",
                            gutterBottom: true,
                            color: "textPrimary"
                          }), React.createElement(DepositPage$MainWithNearSetup, {
                            nearConfig: nearConfig
                          })),
                  maxWidth: "md"
                }));
}

var make = DepositPage;

export {
  emptyFormParams ,
  emptyformErrors ,
  validateAmount ,
  validate ,
  setupNearContext ,
  getQueryParam ,
  ConnectNearWallet ,
  DepositForm ,
  FinalizeTransactionError ,
  FinalizeTransaction ,
  MainContent ,
  MainWithNearSetup ,
  make ,
  
}
/* Api Not a pure module */
