// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Material from "@mui/material";

function ConfirmationDialog(Props) {
  var onConfirm = Props.onConfirm;
  var onCancel = Props.onCancel;
  var isOpenOpt = Props.isOpen;
  var titleOpt = Props.title;
  var confirmTextOpt = Props.confirmText;
  var cancelTextOpt = Props.cancelText;
  var contentOpt = Props.content;
  var isOpen = isOpenOpt !== undefined ? isOpenOpt : false;
  var title = titleOpt !== undefined ? titleOpt : "Confirmation";
  var confirmText = confirmTextOpt !== undefined ? confirmTextOpt : "Yes";
  var cancelText = cancelTextOpt !== undefined ? cancelTextOpt : "Back";
  var content = contentOpt !== undefined ? Caml_option.valFromOption(contentOpt) : "Are you sure you want to perform this action?";
  return React.createElement(Material.Dialog, {
              children: null,
              open: isOpen
            }, React.createElement(Material.DialogTitle, {
                  children: title
                }), React.createElement(Material.DialogContent, {
                  children: content
                }), React.createElement(Material.DialogActions, {
                  children: null
                }, React.createElement(Material.Button, {
                      children: cancelText,
                      onClick: onCancel
                    }), React.createElement(Material.Button, {
                      children: confirmText,
                      onClick: onConfirm,
                      variant: "contained",
                      autoFocus: true
                    })));
}

var make = ConfirmationDialog;

export {
  make ,
  
}
/* react Not a pure module */
